import React from 'react';
import { useSession } from 'next-auth/react';
import Link from 'next/link';

const Home: React.FC = () => {
  const { data: session } = useSession();

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-500 to-purple-600 flex flex-col justify-center items-center text-white">
      <h1 className="text-5xl font-bold mb-8">Welcome to Eglo - Hub</h1>
      {session ? (
        <div className="text-center">
          <p className="text-2xl mb-4">Hello, {session.user?.name}!</p>
          <Link href="/dashboard" className="bg-white text-blue-500 px-6 py-3 rounded-full font-semibold hover:bg-blue-100 transition duration-300">
            Go to Dashboard
          </Link>
        </div>
      ) : (
        <div className="space-x-4">
          <Link href="/login" className="bg-white text-blue-500 px-6 py-3 rounded-full font-semibold hover:bg-blue-100 transition duration-300">
            Login
          </Link>
          <Link href="/signup" className="bg-transparent border-2 border-white text-white px-6 py-3 rounded-full font-semibold hover:bg-white hover:text-blue-500 transition duration-300">
            Sign Up
          </Link>
        </div>
      )}
    </div>
  );
};

export default Home;
